<template>
	<div class="container">	
		<canvas id="circle_canvas" @mousemove="starry.onmousemove($event)"></canvas>
	</div>
</template>

<script>
	import Starry from "./starry.js"
	
	export default {
		name: 'test3',
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				pixelRatio: window.pixelRatio,
				
				starry: null,
			}
		},
		mounted() {
			console.log('test3 mounted')
			
			let circle_canvas = document.getElementById('circle_canvas')
			circle_canvas.width = this.width
			circle_canvas.height = this.height
			let ctx = circle_canvas.getContext('2d')
			ctx.translate(this.width / 2, this.height / 2)
			
			this.starry = new Starry(ctx, 100, this.width, this.height)
			this.starry.init()
			this.update()
		},
		destroyed() {},
		methods: {
			update() {
				window.requestAnimationFrame(this.update)
				this.starry.update()
			}
		},
	}
</script>

<style lang="scss" scoped>
	.container {
		width: 100%;
		height: 100%;
		background-color: black;
	}
	#circle_canvas {
		// width: 100%;
		// height: 100%;

		// background-image: linear-gradient(to bottom, #000, MidnightBlue, deepskyblue, MidnightBlue, #000);
	}
</style>
