export default function Spot(x, y, r, color, speed, sOffset, alp, offset, shadColor, rad) {
	this.x = x
	this.y = y
	this.r = r
	this.color = color
	this.speed = speed
	this.sOffset = sOffset
	this.alp = alp
	this.offset = offset
	this.shadSize = this.r
	this.shadColor = shadColor
	this.rad = rad
}

Spot.prototype.draw = function(circle_ctx) {
	circle_ctx.fillStyle = this.color
	circle_ctx.globalAlpha = this.alp
	circle_ctx.shadowColor = this.shadColor
	circle_ctx.shadowBlur = this.shadSize

	circle_ctx.beginPath()
	circle_ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2, false)
	circle_ctx.fill()
}

Spot.prototype.update = function() {
	this.x = Math.cos(this.rad) * this.speed
	this.y = Math.sin(this.rad) * this.speed
	this.alp += this.offset
	this.speed += this.sOffset
}
