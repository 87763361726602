import Spot from './spot.js'

export default function Starry(ctx, num, width, height) {
	console.log("Starry")
	
	this.circle_ctx = ctx
	
	this.spotTotal = num
	this.width = width
	this.height = height
	
	this.spotArr = []
}

Starry.prototype.init = function() {
	this.initArr(this.spotTotal)
	// this.startAnim()
}

Starry.prototype.onmousemove = function(e) {
	console.log('Starry onmousemove: ', e)
	let x = e.offsetX
	let y = e.offsetY

	for (let i = 0; i < this.spotArr.length; i++) {
		this.spotArr[i].sOffset -= 2
		if (Math.abs(this.spotArr[i].x - x) < 100 &&
			Math.abs(this.spotArr[i].y - y) < 100) {
			this.spotArr[i].alp = 1
		}
	}
}

Starry.prototype.getRandom = function(min, max) {
	return (Math.random() * (max - min) + min)
}

Starry.prototype.initSpot = function() {
	console.log('Starry initSpot')
	
	// let r = this.getRandom(0.1, 0.9)
	let r = this.getRandom(1, 5)
	// let x = 1, y = 1
	let x = this.getRandom(0, this.width)
	let y = this.getRandom(0, this.height)
	let speed = this.getRandom(1, this.width / 2)
	let color = "white"
	let alp = this.getRandom(0.1, 0.9)
	let offset = this.getRandom(0.01, 0.02)
	// let shadColor = "aqua"
	let shadColor = "black"
	let rad = (this.getRandom(0, 360) * Math.PI) / 180
	let sOffset = this.getRandom(0.1, 2)

	this.spotArr.push(
		new Spot(x, y, r, color, speed, sOffset, alp, offset, shadColor, rad)
	)
}

Starry.prototype.initArr = function(total) {
	console.log('Starry initArr: ', total)
	
	for (let i = 0; i < total; i++) {
		this.initSpot()
	}
}

Starry.prototype.update = function() {
	// if (this.keyDown == false) {
	// 	this.circle_ctx.clearRect(-this.width / 2, -this.height / 2, this.width, this.height)
	// }

	// if (this.keyDown == true) {
	// 	this.circle_ctx.fillStyle = 'rgba(0, 0, 0, 0.1)'
	// 	this.circle_ctx.rect(-this.width / 2, -this.height / 2, this.width, this.height)
	// 	this.circle_ctx.fill()
	// }
	
	this.circle_ctx.fillStyle = 'rgba(0, 0, 0, 0.1)'
	this.circle_ctx.rect(-this.width / 2, -this.height / 2, this.width, this.height)
	this.circle_ctx.fill()

	for (let i = 0; i < this.spotArr.length; i++) {
		this.spotArr[i].update()
		this.spotArr[i].draw(this.circle_ctx)

		if (this.spotArr[i].x > this.width / 2 || this.spotArr[i].x < -this.width / 2 ||
			this.spotArr[i].y > this.height / 2 || this.spotArr[i].y < -this.height / 2) {
			this.spotArr.splice(i, 1)

			this.initSpot()
		}

		if (this.spotArr[i].alp < 0 || this.spotArr[i].alp > 1) {
			this.spotArr[i].offset = -this.spotArr[i].offset
		}
	}
}